<template>
  <div class="background">
    <div class="header">

    </div>
  <div class="content">
    <span style="font-weight: bold;font-size: 18px;display: block;text-align: center;margin-bottom: 20px">完善账户信息</span>
    <el-form  :model="registerData" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="registerData.nickname" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="registerData.email" style="width: 400px" disabled></el-input>
      </el-form-item>
      <el-form-item label="手机">
        <el-input v-model="registerData.phone" style="width: 200px"></el-input>
        <el-button @click="getPhoneCode">{{ codeButton.name }}</el-button>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="registerData.phonecode" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="操作">
        <el-button  @click=saveUserInfo type="primary" :disabled="registerData.phone==''||registerData.phonecode==''">
          保存</el-button>

      </el-form-item>
    </el-form>
  </div>
  </div>
</template>

<script>
import {MetaMessage} from "../util";
import {checkPhoneCode, getPhoneCode, requestLogin} from "../api/api";

export default {
  name: "CompleteUserInfo",
  data(){
    return{
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      registerData: {
        country_code: '86',
        phonecode:'',
        method:'clm'
      },
      codeButton: {
        isDisabled: false,
        name: '发送验证码',
        time: 60
      },
      timer:null,
      metaMessage: new MetaMessage(),
    }
  },
  mounted() {
    Object.assign(this.registerData,this.userInfo);
    this.registerData.nickname=this.userInfo.userName;
  },
  methods:{
    saveUserInfo(){
      let params = {
        type: 'signup',
        content: this.registerData.phonecode
      };
      checkPhoneCode(params).then(res => {
        if (res.data.code === 0) {
          requestLogin(null, this.registerData, (res) => {
            if (res.user && res.user.code === 0) {

              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push('/index')
              }
            }else{
              this.metaMessage.error(res.user.msg)
            }
          })
        }
      });


    },
    isEmpty(str){
      return str==null||str=='';
    },
    getPhoneCode() {
      this.codeButton.isDisabled = true;
      let type = {
        type: 'signup'
      }
      let params = {
        phone: this.registerData.country_code === '' || this.registerData.country_code === undefined
            ? '86-' + this.registerData.phone : this.registerData.country_code + '-' + this.registerData.phone
      }
      getPhoneCode(type, params).then(res => {
        if (res.data.code === 0) {
          this.timer = window.setInterval(() => {
            this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
            --this.codeButton.time;
            if (this.codeButton.time < 0) {
              this.resetCodeButton();
            }
          }, 1000);
        } else if (res.data.code === 5) {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
            // eslint-disable-next-line no-unused-vars
            callback: (_) => {
              this.timer = window.setInterval(() => {
                this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
                --this.codeButton.time;
                if (this.codeButton.time < 0) {
                  this.resetCodeButton();
                }
              }, 1000);
            },
          });
        }
      })
    },
    resetCodeButton: function () {
      this.codeButton = {
        isDisabled: false,
        name: '发送验证码',
        time: 60
      };

      window.clearInterval(this.timer);
    },
  }
}
</script>

<style scoped>
.background{
  background-color: rgb(248, 249, 250);
  height: 100%;
}
.header{
  height: 100px;
  padding: 20px;
}


.content{
  width: 600px;margin: 0 auto;padding: 30px;text-align: left;
  background-color: white;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
}
</style>
