<template>
  <div  id="index" class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done" style="height: 100vh; overflow-y: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-side-menu :active-index="1"></contract-side-menu>
        <div class="index-right-panel flex-auto">
        <div class="flex flex-auto flex-column overflow-y-auto pl1 right-panel-content">

          <MetaRightSideHeader>
            <template v-if="!notFirst" #title-area>
              <a class="special-action truncate" @click="goToHelpCenter">首次使用指南</a>
            </template>
            <template #title v-else>
                  更简单，更强大
            </template>
            <template #middle-right>
              <div class="index-top-right-buttons flex">
                <div class="bg-concord-near-white ml3 ">
                  <div data-test-algolia-input-container="" id="algolia-search-box-container" class="relative h-100 bw1"
                       style="width: 253px;">
                    <div class="relative concord-mid-gray">
                      <input placeholder="请输入关键词" id="searchInput"
                             class="bzbb ember-text-field ember-view bg-white placeholder-concord-mid-gray hover-concord-mid-gray input-reset w-100 h-100 bn pl5 outline-0 fw4 f5 shadow size-medium"
                             aria-label="Search" type="text" @keyup.enter="onSearchInputEnter">
                      <div style="transform: translateY(-50%)" class="absolute left-0 flex items-center top-50 h-100 ph2">
                        <i class="material-icons pl2 f3 concord-mid-gray hover-concord-mid-gray ais-search-box--icon pe--none" aria-hidden="true">
                          search
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                    id="invite-btn"
                    v-if="userInfo && userInfo.mainSiteTeamId" @click="inviteUser()" data-test-locked="false" style=""
                    class="button-flex button-reset fw5 outline-0 ba br1 b--radical-red bg-white radical-red hover-b--venetian-red hover-venetian-red ph3 f5 size-medium  pointer ember-view nowrap  ml3"
                    type="button">
                  <i aria-hidden="true" class="material-icons md-size2 v-mid">
                    person_add
                  </i>
                  <span class="ml1 v-mid">
                邀请成员
              </span>
                </button>
                <el-tooltip content="开发中">
                  <button
                      data-tag-id="right-side-upgrade"
                      data-test-locked="false"
                      class="button-flex button-reset fw5 outline-0 ba br1 b--transparent t-grad-pink white focus-t-grad-dark-pink hover-t-grad-dark-pink ph3 f5 size-medium  pointer ember-view nowrap  nowrap ml3"
                      data-test-upgrade-button="" type="button">
                    <i><svg-icon name="upgrade"></svg-icon></i>
                    <span class="ml1 v-mid">
                  升级
                </span>
                  </button>
                </el-tooltip>

              </div>

            </template>
          </MetaRightSideHeader>
<!--          <header class="pt3">-->


<!--            <div class="flex pr4 mt2 pt1">-->
<!--              <h1 class="concord-mid-gray f3 fw5 flex flex-auto items-center mb2 mt0 nowrap size-medium truncate"-->
<!--                  title="What would you like to do?">-->
<!--                你想做什么？-->
<!--              </h1>-->
<!--              <div class="bg-concord-near-white mb2 ml3">-->
<!--                <div data-test-algolia-input-container="" id="algolia-search-box-container" class="relative h-100 bw1"-->
<!--                     style="width: 253px;">-->
<!--                  <div class="relative concord-mid-gray">-->
<!--                    <input placeholder="请输入关键词" id="searchInput"-->
<!--                           class="ember-text-field ember-view bg-white placeholder-concord-mid-gray hover-concord-mid-gray input-reset w-100 h-100 bn pl5 outline-0 fw4 f5 shadow size-medium"-->
<!--                           aria-label="Search" type="text" @keyup.enter="onSearchInputEnter">-->
<!--                    <div style="transform: translateY(-50%)" class="absolute left-0 flex items-center top-50 h-100 ph2">-->
<!--                      <i class="material-icons pl2 f3 concord-mid-gray hover-concord-mid-gray ais-search-box&#45;&#45;icon pe&#45;&#45;none" aria-hidden="true">-->
<!--                        search-->
<!--                      </i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <button-->
<!--                  id="invite-btn"-->
<!--                  v-if="userInfo && userInfo.mainSiteTeamId" @click="inviteUser()" data-test-locked="false" style="" title=""-->
<!--                      class="button-flex button-reset fw5 outline-0 ba br1 b&#45;&#45;radical-red bg-white radical-red hover-b&#45;&#45;venetian-red hover-venetian-red ph3 f5 size-medium  pointer ember-view nowrap mb2 ml3"-->
<!--                      type="button">-->
<!--                <i aria-hidden="true" class="material-icons md-size2 v-mid">-->
<!--                  person_add-->
<!--                </i>-->
<!--                <span class="ml1 v-mid">-->
<!--                邀请成员-->
<!--              </span>-->
<!--              </button>-->
<!--              <button-->
<!--                  data-test-locked="false" title=""-->
<!--                      class="button-flex button-reset fw5 outline-0 ba br1 b&#45;&#45;transparent t-grad-pink white focus-t-grad-dark-pink hover-t-grad-dark-pink ph3 f5 size-medium  pointer ember-view nowrap mb2 nowrap ml3"-->
<!--                      data-test-upgrade-button="" type="button">-->
<!--                <i><svg-icon name="upgrade"></svg-icon></i>-->
<!--                <span class="ml1 v-mid">-->
<!--                  升级-->
<!--                </span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="flex mb4">-->
<!--              <div data-test-disabled="false"-->
<!--                   class="ba flex gray outline-0 pa3 b&#45;&#45;white pointer hover-b&#45;&#45;concord-blue hover-concord-blue bg-white mr4 mb3 w-third"-->
<!--                   style="box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;" data-test-home-draft-card="" role="button"-->
<!--                   tabindex="0" @click="openNewPage('newDocument',{step:2,mode:1})">-->
<!--                  <article class="flex-auto">-->
<!--                    <header>-->
<!--                      <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" class="fill concord-blue svg-size2 v-mid stroke"><path d="M2.228 15.228l2.115-6.346 4.231-1.693m-6.346 8.039l6.346-2.115 1.693-4.231m-8.039 6.346l3.808-3.808m2.538-4.23l2.962-4.654L14.92 5.92l-4.653 2.962M8.574 7.189l1.693 1.693"></path></svg>-->
<!--                      <span class="dark-gray f4 fw5 ml2 v-mid">-->
<!--                        起草 &amp; 定稿-->
<!--                      </span>-->
<!--                    </header>-->
<!--                    <div class="f5 flex fw4 gray mt2">-->
<!--                      起草、共享、协商并签署新文件-->
<!--                    </div>-->
<!--                  </article>-->
<!--                <aside class="flex items-center pl3">-->
<!--                  <span aria-hidden="true" class="material-icons md-size2">-->
<!--                    arrow_forward-->
<!--                  </span>-->
<!--                </aside>-->
<!--              </div>-->
<!--              <div data-test-disabled="false"-->
<!--                   class="ba flex gray outline-0 pa3 b&#45;&#45;white pointer hover-b&#45;&#45;concord-blue hover-concord-blue bg-white mr4 mb3 w-third"-->
<!--                   style="box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;" data-test-home-contract-card="" role="button"-->
<!--                   tabindex="0" @click="openNewPage('newDocument', { step: 2, mode: 2 })">-->
<!--                  <article class="flex-auto">-->
<!--                    <header>-->
<!--                      <svg width="12" height="15" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg" class="fill concord-blue svg-size2 v-mid "><path d="M3.5 11.333h5v-5h3.333L6 .5.167 6.333H3.5v5zM.167 13h11.666v1.667H.167V13z"></path></svg>-->
<!--                      <span class="dark-gray f4 fw5 ml2 v-mid">-->
<!--                        存储 &amp; 跟踪-->
<!--                      </span>-->
<!--                    </header>-->
<!--                    <div class="f5 flex fw4 gray mt2">-->

<!--                      存储以前签署的文档并跟踪截止日期-->

<!--                    </div>-->
<!--                  </article>-->
<!--                  <aside class="flex items-center pl3">-->
<!--                    <span aria-hidden="true" class="material-icons md-size2">-->
<!--                      arrow_forward-->
<!--                    </span>-->
<!--                  </aside>-->
<!--              </div>-->
<!--              <div data-test-disabled="false"-->
<!--                   class="ba flex gray outline-0 pa3 b&#45;&#45;white pointer hover-b&#45;&#45;concord-blue hover-concord-blue bg-white mr4 mb3 w-third"-->
<!--                   style="box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;" data-test-home-template-card="" role="button"-->
<!--                   tabindex="0" @click="openNewPage('newDocument',{step:2,mode:3})">-->
<!--                  <article class="flex-auto">-->
<!--                    <header>-->
<!--                      <svg width="17" height="20" viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg" class="fill concord-blue svg-size2 v-mid "><path d="M12.333.833h-10C1.417.833.667 1.583.667 2.5v11.667h1.666V2.5h10V.833zM11.5 4.167l5 5V17.5c0 .917-.75 1.667-1.667 1.667H5.658C4.742 19.167 4 18.417 4 17.5l.008-11.667c0-.916.742-1.666 1.659-1.666H11.5zM10.667 10h4.583l-4.583-4.583V10z"></path></svg>-->
<!--                      <span class="dark-gray f4 fw5 ml2 v-mid">-->
<!--                        创建模板-->
<!--                      </span>-->
<!--                    </header>-->
<!--                    <div class="f5 flex fw4 gray mt2">-->

<!--                      使用标准化模板自动创建文档-->

<!--                    </div>-->
<!--                  </article>-->
<!--                  <aside class="flex items-center pl3">-->
<!--                    <span aria-hidden="true" class="material-icons md-size2">-->
<!--                      arrow_forward-->
<!--                    </span>-->
<!--                  </aside>-->
<!--              </div>-->
<!--            </div>-->
<!--          </header>-->
          <div class="right-panel-main flex pl2">
            <div data-test-home="true" class="bg-concord-near-white flex-auto mw-70 mr3">
              <div class="index-center-scroll custom-scroller">
                <div class="contract-info-card-container pb3 pl1 flex-grow-1">
                  <div class="flex flex-column bulletin mr3 shadow">
                    <div class="flex flex-column bg-white pb3 flex-grow-1">
                      <div class="h2 ph4 pb3 mt3 fw6 text-align-left">
                        待我办理
                        <!--                      Your tasks-->
                      </div>
                      <div style="padding-bottom: 10px;" class="flex-grow-1 flex flex-column">
                        <div v-show="tasks.length === 0" class="w-100 h-25 ph4 mt4 fw4 f5 silver">
                          没有待我办理的文档
                        </div>
                        <div v-show="tasks.length">
                          <div v-for="(task, index1) in tasks" :key="task.data.id" class="task-item flex-grow-1" @click="onTaskClick(task)">
                            <div class="ember-view no-underline">
                              <div data-test-recent-agreement="0" style="padding-top: 10px; padding-bottom: 10px;"
                                   class="task-row flex ph4 bg-white hover-bg-near-white">
                                <div class="flex-auto black fw4 lh-title flex items-center" style="max-width: calc(100% - 150px);">
                                  <div class="task-row-left nowrap flex items-center truncate">
                                  <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                                    <span class="dib v-mid" :class="getTaskPointClass(task)"
                                         style="width: 8px; height: 8px; border-radius: 105px;"></span>
                                  </span>
                                    <span class="pending-contract-title flex-grow-1 dib truncate">
                                    {{task.data.title}}
                                  </span>
                                    <div v-if="task.data.tags" class="tags-wrapper flex items-center">
                                      <CertificateTag  v-for="(item,index2) in task.data.tags.filter(item=>item.type===1 && item.status===0)"
                                                       :key="item.id"
                                                       :external-requests="{
                                                        completeTag:(tagId,formData) => {
                                                          return request.completeContractTag(task.data.id,tagId,formData);
                                                        },
                                                        cancelTagCompleteStatus: () => {
                                                          return request.cancelContractTagCompleteStatus(task.data.id,tagId);
                                                        }
                                                     }"
                                                       @tagResolved="handleTagResolved(index1,task,$event)"
                                                       :tagData="item"
                                                       @click.stop  style="margin-left: 10px"

                                      ></CertificateTag>
                                    </div>
                                  </div>
                                </div>
                                <div  class="nowrap black ml-auto text-right text-align-left" style="width: 150px;">
                                  {{task.type=='contract'?transformTimeInfoForList(task.approvalDetail.approvalStages.find((item)=>item.id===task.approvalDetail.approvalProcess.activeApprovalStageId).updateTime,new Date()):transformTimeInfoForList(task.data.updateTime,new Date())}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="color: rgba(162,162,162,1);" v-show="showLoadMoreTaskBtn">
                        <div class="text-btn" @click="loadMoreTask">
                          <span style="margin-right: 10px;height: 15px;display: inline-block"><svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: top;fill: rgb(162, 162, 162)" viewBox="0 0 1024 1024" width="15" height="15"><defs data-reactroot=""></defs><g><path d="M490.666667 601.6L797.866667 298.666667l59.733333 59.733333-302.933333 302.933333-59.733334 64-59.733333-59.733333L128 358.4 187.733333 298.666667l302.933334 302.933333z" fill="rgb(162, 162, 162)" p-id="5488"></path></g></svg></span>
                          <span style="vertical-align: top;height: 15px;line-height:15px;display: inline-block">展开更多</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-column bg-white pb3 bulletin mt3 mr3 shadow">
                    <div class="h2 ph4 pb3 mt3 fw6 text-align-left">
                      我发起的
                    </div>
                    <div v-show="launchedByMe.length === 0" class="w-100 h-25 ph4 mt4 fw4 f5 silver">
                      没有我发起的文档
                    </div>
                    <div v-show="launchedByMe.length">
                      <div v-for="(resource, index) in launchedByMe" :key="index" class="recently-modified-item flex-grow-1" @click="onRecentlyModifiedClick(resource.data)">
                        <div class="ember-view no-underline">
                          <div data-test-recent-agreement="0" style="padding-top: 10px; padding-bottom: 10px;"
                               class="task-row flex ph4 bg-white hover-bg-near-white">
                            <div class="flex-auto black fw4 lh-title flex items-center" style="max-width: calc(100% - 150px);">
                              <div class="nowrap flex items-center truncate">
                            <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                              <div class="dib v-mid" :class="getRecentlyModifyPointClass(resource.data)"
                                   style="width: 8px; height: 8px; border-radius: 105px;">
                              </div>
                            </span>

                                <span data-test-recent-agreement-title-label="" class="dib truncate">
                              {{resource.data.title}}
                            </span>
                              </div>
                              <el-tag v-if="resource.type==='Contract'" type="info" size="small" class="approval-stage info-tag">
                                <span>{{`${resource.approvalDetail.approvalStages.filter((item)=>item.status!==0).length+1}/${resource.approvalDetail.approvalStages.length}`}}</span>
                              </el-tag>
                              <el-tag type="info" size="small" v-else class="approval-stage info-tag"><span>模板</span></el-tag>
                            </div>

                            <div  class="nowrap black ml-auto text-right text-align-left" style="width: 150px;">
                              {{transformTimeInfoForList(resource.data.updateTime,new Date())}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="w-100 h-25 ph4 mt4 fw4 f5 silver" style="padding-bottom: 10px;" v-if="recentlyModified.length == 0">暂无修改</div>
                  </div>
                  <div class="flex flex-column bg-white pb3 bulletin mt3 mr3 shadow">
                    <div class="h2 ph4 pb3 mt3 fw6 text-align-left">
                      最近修改
                    </div>
                    <div v-show="recentlyModified.length">
                      <div v-for="(resource, index) in recentlyModified" class="recently-modified-item flex-grow-1" @click="onRecentlyModifiedClick(resource)">
                        <div class="ember-view no-underline">
                          <div data-test-recent-agreement="0" style="padding-top: 10px; padding-bottom: 10px;"
                               class="task-row flex ph4 bg-white hover-bg-near-white">
                            <div class="flex-auto black fw4 lh-title flex items-center" style="max-width: calc(100% - 150px);">
                              <div class="nowrap flex items-center truncate">
                            <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                              <div class="dib v-mid" :class="getRecentlyModifyPointClass(resource)"
                                   style="width: 8px; height: 8px; border-radius: 105px;">
                              </div>
                            </span>

                                <span data-test-recent-agreement-title-label="" class="dib truncate">
                              {{resource.title}}
                            </span>
                              </div>
                            </div>
                            <div  class="nowrap black ml-auto text-right  text-align-left" style="width: 150px;">
                              {{transformTimeInfoForList(resource.updateTime,new Date())}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 h-25 ph4 mt4 fw4 f5 silver" style="padding-bottom: 10px;" v-show="recentlyModified.length == 0">暂无修改</div>
                  </div>
                  <div class="flex flex-column bg-white pb3 bulletin mt3 mr3 shadow">
                    <div class="h2 ph4 pb3 mt3 fw6 text-align-left">
                      邀请我的
                    </div>
                    <div v-for="(resource, index) in sharedContracts" class="recently-modified-item flex-grow-1" @click="onSharedContractClick(resource)">
                      <div class="ember-view no-underline">
                        <div data-test-recent-agreement="0" style="padding-top: 10px; padding-bottom: 10px;"
                             class="task-row flex ph4 bg-white hover-bg-near-white">
                          <div class="flex-auto black fw4 lh-title flex items-center" style="max-width: calc(100% - 150px);">
                            <div class="nowrap flex items-center truncate">
                            <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                              <div class="dib v-mid" :class="getRecentlyModifyPointClass(resource)"
                                   style="width: 8px; height: 8px; border-radius: 105px;">
                              </div>
                            </span>

                              <span  data-test-recent-agreement-title-label="" class="dib truncate">
                              {{resource.contractName}}
                            </span>
                            </div>
                          </div>
                          <div  class="nowrap black ml-auto text-right text-align-left" style="width: 150px;text-overflow: ellipsis;overflow: hidden">
                            {{resource.inviterName}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 h-25 ph4 mt4 fw4 f5 silver" style="padding-bottom: 10px;" v-if="sharedContracts.length == 0">暂无协作邀请</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mr4 w-third">
              <div class="flex flex-column flex-auto text-align-left">
                <div class="concord-dark-gray flex fw6 mb3 justify-between">
                  <span>
                    所有文档
                  </span>
                  <span>
                    {{ statisticsData.total }}
                  </span>
                </div>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                     @click="jumpToContractListView(0)">
                  <div data-test-agreement-label="Draft" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-light-sky-blue" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      模版
                    </span>
                    <span data-test-agreement-count="" class="">
                      {{ statisticsData.template }}
                    </span>
                  </div>
                </div>
<!--                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"-->
<!--                   @click="jumpToContractListView(1)">-->
<!--                  <div data-test-agreement-label="Draft" class="flex items-center mv2 hover-concord-dark-blue">-->
<!--                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">-->
<!--                      <div class="dib v-mid stage-white" style="width: 8px; height: 8px; border-radius: 105px;"></div>-->
<!--                    </span>-->
<!--                    <span data-test-agreement-status="" class="flex-auto">-->
<!--                      模板草稿-->
<!--                    </span>-->
<!--                    <span data-test-agreement-count="" class="">-->
<!--                      {{ statisticsData.pro }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->

                <span class="bb b--light-gray mv2"></span>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                   @click="jumpToContractListView(2)">
                  <div data-test-agreement-label="Review" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-alto" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      草稿
                    </span>
                    <span data-test-agreement-count="" class="">
                      {{ statisticsData.draft }}
                    </span>
                  </div>
                </div>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                   @click="jumpToContractListView(3)">
                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-dandelion" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      审批中
                    </span>
                    <span data-test-agreement-count="" class="" >
                      {{ statisticsData.launchReadyToSign }}
                    </span>
                  </div>
                </div>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                   @click="jumpToContractListView(4)">
                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-purple" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      定稿
                    </span>
                    <span data-test-agreement-count="" class="" >
                      {{ statisticsData.readyToSignPassed }}
                    </span>
                  </div>
                </div>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                   @click="jumpToContractListView(5)">
                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-mona-lisa" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      已驳回
                    </span>
                    <span data-test-agreement-count="" class="" >
                      {{ statisticsData.readyToSignRefused }}
                    </span>
                  </div>
                </div>
<!--                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"-->
<!--                   @click="jumpToContractListView(0)">-->
<!--                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">-->
<!--                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">-->
<!--                      <div class="dib v-mid bg-mona-lisa" style="width: 8px; height: 8px; border-radius: 105px;"></div>-->
<!--                    </span>-->
<!--                    <span data-test-agreement-status="" class="flex-auto">-->
<!--                      已签署审批中-->
<!--                    </span>-->
<!--                    <span data-test-agreement-count="" class="" title="0">-->
<!--                      {{ statisticsData.launchSigned }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"-->
<!--                   @click="jumpToContractListView(0)">-->
<!--                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">-->
<!--                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">-->
<!--                      <div class="dib v-mid bg-mona-lisa" style="width: 8px; height: 8px; border-radius: 105px;"></div>-->
<!--                    </span>-->
<!--                    <span data-test-agreement-status="" class="flex-auto">-->
<!--                      已签署审批拒绝-->
<!--                    </span>-->
<!--                    <span data-test-agreement-count="" class="" title="0">-->
<!--                      {{ statisticsData.signedRefused }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
                <span class="bb b--light-gray mv2"></span>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                   @click="jumpToContractListView(6)">
                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-mint" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      已签署
                    </span>
                    <span data-test-agreement-count="" class="" >
                      {{ statisticsData.signed }}
                    </span>
                  </div>
                </div>
                <div class="ember-view pointer hover-concord-dark-blue concord-dark-gray no-underline"
                     @click="jumpToContractListView(7)">
                  <div data-test-agreement-label="Signing" class="flex items-center mv2 hover-concord-dark-blue">
                    <span data-test-badge="unknown-duration-badge" style="line-height: 0px;" class="dib mr3">
                      <div class="dib v-mid bg-dark" style="width: 8px; height: 8px; border-radius: 105px;"></div>
                    </span>
                    <span data-test-agreement-status="" class="flex-auto">
                      已取消
                    </span>
                    <span data-test-agreement-count="" class="">
                      {{ statisticsData.canceled }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <invite-user :teamId="userInfo.mainSiteTeamId" ref="invite"></invite-user>

<!--    <el-dialog-->
<!--            v-for="(contract, index) in waitSetCalendarContracts"-->
<!--             v-model="showContractCalendar[`${contract.id}`]"-->
<!--            title=""-->
<!--            width="50%"-->
<!--            custom-class="contract-calendar-dialog"-->
<!--            @closed="setContractCalendarDialogClosed(contract, index)">-->
<!--      <ContractCalendar :contract="contract" @callback="waitSetCalendarContracts.splice(index, 1)">-->

<!--      </ContractCalendar>-->
<!--    </el-dialog>-->

    <ContractCalendars></ContractCalendars>

    <Mask
        :display="showCompleteUserNamePopover ? 'block' : 'none'"
        :z-index="1001"
    >
      <div class="completeUserName-container">
        <complete-user-name @onSuccess="showCompleteUserNamePopover = false"></complete-user-name>
      </div>
    </Mask>
  </div>

<!--  <div class="feedback">-->
<!--    <FeedbackButton></FeedbackButton>-->
<!--  </div>-->
</template>

<script>
// 合同的过滤器类型
import config, {DEPLOY_TYPE} from "../../config";
import {
  getContractStatisticsData,
  getContractTasks,
  getTeamOrganizations,
  organizationInviteMember,
  getWaitForCalendarContracts,
  getContractsShareWithMe,
  getMyInfo,
  updateContractShareStatus,
  getResourceList,
  getLaunchedByMe,
} from "../../api/api";
import * as request from "../../api/api";
import leftMenuFooter from "../../components/LeftMenuFooter";
import {ElLoading, ElMessage, ElNotification} from "element-plus";
import {MetaMessage, singleListenerManager,colorLog,transformTimeInfoForList} from "../../util";
import ContractSideMenu from "../../components/ContractSideMenu";
import InviteUser from "../../components/InviteUser";
import ContractCalendars from "../../components/ContractCalendars";
import SvgIcon from "../../components/SvgIcon/svgIcon";
import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
import FeedbackButton from "../../components/FeedbackButton";
import {globalTagClick} from "../../assets/js/tag/tag";
import CompleteUserInfo from "../CompleteUserInfo_bak";
import Mask from "../../components/universalUI/Mask";
import CompleteUserName from "../../components/CompleteUserName";
import CertificateTag from "../../components/tag/CertificateTag";
import {TagType} from "../../components/tag/configure";
const ContractFilterType = {
  ALL: 0,
  WAIT_APPROVE_BY_ME: 1,
  MY_CONTRACT_WAIT_APPROVE: 2,
  WAIT_SETTING_META_APPROVAL_PROCESS: 3,
  SIGNED: 4,
};

const CLMResourceType = {
  PRO:1,
  PUBLISHED_TEMPLATE:2,
  CONTRACT:4,
  ALL:7
}
export default {
  name: "ContractIndex",
  components: {
    CertificateTag,
    CompleteUserName,
    Mask,
    MetaRightSideHeader,
    SvgIcon, InviteUser, ContractSideMenu, ContractCalendars},
  data: function () {
    return {
      request,
      templateHost: config.baseUrl,
      metaMessage: new MetaMessage(),
      inviteOrganizationId: this.$route.query.vo?this.$route.query.vo.split(","):null,
      statisticsData: {},
      tasks: [],
      launchedByMe:[],
      recentlyModified: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      loadingInstance: null,
      waitSetCalendarContracts: [],
      showContractCalendar: {
      },
      sharedContracts:[],
      showQRcode:false,
      errorMsg:null,
      showCompleteUserNamePopover:false,
      notFirst: localStorage.getItem("notFirst"),
      CLMResourceType,
      transformTimeInfoForList,
      showLoadMoreTaskBtn:false
    };
  },
  mounted() {
    // if (this.inviteOrganizationId) {
    //   console.log('this.inviteOrganizationId === ', this.inviteOrganizationId)
    //   organizationInviteMember(this.inviteOrganizationId).then(res => {
    //     if (res.data.code === 0) {
    //       this.metaMessage['info']('加入组织成功');
    //       sessionStorage.setItem('userInfo', "{}")
    //       getMyInfo().then(res=>{
    //         this.userInfo = res.data.userInfo;
    //         this.loadData();
    //         this.$router.push('/index');
    //         sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo));
    //         singleListenerManager.emit("sessionStorage","update sessionStorage")
    //       })
    //     }else {
    //       console.error('organizationInviteMember  res:', res);
    //       this.errorMsg = res.data.msg;
    //       return;
    //       // this.metaMessage['error']('加入组织失败  message:' + res.data.msg);
    //     }
    //     this.loadData();
    //   }).catch(err => {
    //     console.error('organizationInviteMember  err:', err);
    //     this.metaMessage['error']('加入组织失败');
    //   })
    // }else{
    //   this.loadData();
    // }
    this.loadData();

    //用户没有填写姓名,弹出"补全姓名"窗口
    if(config.deployType !== DEPLOY_TYPE.TEST){
      let str = sessionStorage.getItem("userInfo");
      if(!str) return;
      let userInfo = JSON.parse(str);
      // return;
      if(userInfo.hasSetName) return;
      this.showCompleteUserNamePopover = true;
    }


  },
  methods: {
    globalTagClick,
    loadData: function () {
      getContractStatisticsData().then(res => {
        console.log('getContractStatisticsData   res === ', res);
        if (res.data.code === 0) {
          this.statisticsData = res.data.data;
        } else {
          this.metaMessage['error'](res.data.msg);
        }
      }).catch(err => {
        console.error('getContractStatisticsData  err:', err);
        this.metaMessage['error']('获取统计数据失败');
      });
      getContractTasks(0,11).then(res => {
        console.log('getContractTasks  res  === ', res);
        this.tasks = res.data.data;
        if(this.tasks.length>10){
          this.showLoadMoreTaskBtn=true;
          this.tasks = this.tasks.slice(0,10)
        }else{
          this.showLoadMoreTaskBtn=false;
        }
      }).catch(err => {
        console.error('getContractTasks  err:', err);
        this.metaMessage['error']('获取任务失败');
      })

      getLaunchedByMe().then(res=>{
        this.launchedByMe=res.data.data;
      })
      const params = {
        trashed : false,
        orderBy : 'update_time'
      };
      getResourceList(params).then(res => {
        console.log('getResourceList  res  === ', res);
        this.recentlyModified = res.data.data.content;
      }).catch(err => {
        console.error('getResourceList  err:', err);
        this.metaMessage['error']('获取最近修改的文档失败');
      })
      getContractsShareWithMe().then(res =>{
        this.sharedContracts = res.data.data;
        let unWatched = this.sharedContracts.filter((item)=>item.status==0)
        let index=0;
        let interval = setInterval(()=>{
          if (index < unWatched.length) {
            let item = unWatched[index];
            ElNotification({
              title: '协作邀请',
              message: `${item.inviterName}向您发起了协作邀请`,
              duration: 3000,
              onClick: () => {
                globalTagClick('contract-index-receive-cooperation');
                let updateItem = {id: item.id, contractId: item.contractId, status: 1, uid: this.userInfo.uid}
                updateContractShareStatus(updateItem).then(res => {
                  if (res.status == 200) {
                    window.open(item.url)
                  }
                })
              },
            },)
            index++;
          } else {
            clearInterval(interval)
          }
        },100)
      }).catch(err =>{
        console.error('getContractsShareWithMe  err:', err);
        this.metaMessage['error']('获取协作列表失败');
      })
    },
    handleTagResolved(taskIndex,sourceTask,tagData){
      sourceTask.data.tags = sourceTask.data.tags.filter(t => t.id != tagData.id);
      if(sourceTask.data.tags.filter(t => t.type === TagType.NEED_RESOLVE).length === 0 && sourceTask.data.approvalStatus !== 10){
        this.tasks = this.tasks.filter(t => t.data.id !== sourceTask.data.id);
      }
    },
    goToHelpCenter(){
      //本地存isFirst,用来判断是否显示"首次使用指南"
      localStorage.setItem("notFirst",1);
      this.$router.push({name:'helpCenter',query:{index:'a0a0'}});
    },
    getTaskPointClass: function (task) {
      if (task.type === 'Contract') {
        if (task.data.approvalStatus === 0) {
          return 'bg-alto';
        } else if (task.data.approvalStatus === 21) {
          return 'bg-mint';
        } else if(task.data.approvalStatus === 10){
          return 'bg-dandelion';
        } else if(task.data.approvalStatus === 12){
          return 'bg-mona-lisa';
        } else if(task.data.approvalStatus === 11){
          return 'bg-purple';
        }

        else {
          return 'bg-mona-lisa';
        }
      }

      return 'bg-alto';
    },
    getRecentlyModifyPointClass: function (data) {
      if (data.resourceType === CLMResourceType.CONTRACT) {
        if (data.approvalStatus === 0) {
          return 'bg-alto';
        } else if (data.approvalStatus === 21) {
          return 'bg-mint';
        } else if(data.approvalStatus === 10){
          return 'bg-dandelion';
        }
        else if(data.approvalStatus === 12){
          return 'bg-mona-lisa';
        }
        else if(data.approvalStatus === 11){
          return 'bg-purple';
        }
      }
      else if(data.resourceType===CLMResourceType.PUBLISHED_TEMPLATE){
        return 'bg-light-sky-blue';
      }
      else{
        return "stage-white"
      }


    },
    onSearchInputEnter: function () {
      globalTagClick('contract-index-search-keyword');
      const searchInput = document.querySelector('#searchInput');
      const searchKey = searchInput.value;
      this.$router.push({
        name: 'advancedSearch',
        query: {
          query: searchKey
        }
      })
    },
    openNewPage: function (name, query) {
      this.$router.push({
        name: name,
        query: query
      })
    },

    jumpToContractListView: function (filterType) {
      globalTagClick('contract-index-all-doc-'+filterType);
      this.$router.push({
        name: 'files',
        query:{
          status:filterType
        }
      })
    },
    onTaskClick: function (task) {
      console.log('onTaskClick  task === ', task);
      if (task.type === 'Contract') {
        this.$router.push({
          name: 'contractView',
          query: {
            id: task.data.id,
            exitTo:'contractIndex'
          }
        })
      } else if (task.type === 'PublishedTemplate') {
        this.$router.push({
          name: 'templatePublish',
          query: {
            templateId: task.data.id,
            exitTo:'contractIndex'
          }
        })
      }
    },
    onRecentlyModifiedClick: function (resource) {
      globalTagClick('contract-index-recently-contract');
      console.log('onRecentlyModifiedClick  resource === ', resource);
      if (resource.resourceType === CLMResourceType.PUBLISHED_TEMPLATE) {
        this.$router.push({
          name: 'templatePublish',
          query: {
            templateId: resource.id,
            exitTo:'contractIndex'
          }
        })
      } else if (resource.resourceType === CLMResourceType.CONTRACT) {
        this.$router.push({
            name: 'contractView',
            query: {
              id: resource.id,
              exitTo:'contractIndex'
            }
          })
        // let contractViewPage = this.$router.resolve({
        //   name: 'contractView',
        //   query: {
        //     id: resource.id
        //   }
        // })
        // window.open(contractViewPage.href)
      } else if (resource.resourceType === CLMResourceType.PRO) {
        this.watchProFile(resource.fileId, resource.name);
      }
    },
    onSharedContractClick:function (sharedItem){
      globalTagClick('contract-index-share-contract');
      if(sharedItem.status===0){
        let updateItem = {id:sharedItem.id,contractId:sharedItem.contractId,status:1,uid:this.userInfo.uid}
        updateContractShareStatus(updateItem).then(res=>{
          if(res.status==200){
            window.open(sharedItem.url)
          }
        })
      }else{
        window.open(sharedItem.url)
      }

    },
    watchProFile: function (fileId, fileName) {
      this.$router.push({
        name:"doc",
        query:{
          fileId:fileId,
          exitTo:'contractIndex'
        }
      })
    },
    openLoading: function () {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
    inviteUser(){
      globalTagClick('right-side-invite-user')
      this.$refs.invite.inviteUser();
    },
    // setContractCalendarDialogClosed: function (contract, index) {
    //   setContractCalendar({"contractId": contract.id, "dates": []}).then(res => {
    //     console.log('setContractCalendar   res ==== ', res);
    //   }).catch(err => {
    //     console.error('setContractCalendar err contract.id === ' + contract.id + ' === ', err);
    //   });
    // }
    loadMoreTask(){
      this.showLoadMoreTaskBtn=false
      getContractTasks(0,9999).then(res => {
        console.log('getContractTasks  res  === ', res);
        this.tasks = res.data.data;
      }).catch(err => {
        console.error('getContractTasks  err:', err);
        this.metaMessage['error']('获取任务失败');
      })
    }
  },
}
</script>

<style scoped>

body {
  background-color: #F8F9FA;
  line-height: 1.42857;
  transition: margin 250ms ease 0s;
  margin-top: 0px;
}
.index-right-panel{
  margin-left:-.25rem;
}
.h-inherit {
  height: inherit;
}
.info-tag{
  margin-left: 1ch;
}
.h-100 {
  height: 100%;
}
.flex-column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.pl1 {
  padding-left: 0.25rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}



.mr3 {
  margin-right: 14px;
}

.pt4 {
  padding-top: 2rem;
}

.pr3 {
  padding-right: 14px;
}

.pl4 {
  padding-left: 28px;
}



h2 {
  height: 2rem;
}

.items-center, .side-menu-toggle {
  align-items: center;
}

.mt4 {
  margin-top: 2rem;
}

.mb4 {
  margin-bottom: 2rem;
}

.pt3 {
  padding-top: 1rem;
}

.size-medium {
  min-height: 36px;
}
.t-grad-pink{
  background-color: var(--lightBlue);
}
.hover-t-grad-pink:hover{
  background-color: var(--darkBlue);
}
.hover-t-grad-dark-pink:hover{
  background-color: var(--darkBlue);
}
/*.focus-t-grad-pink:focus, .hover-t-grad-pink:hover, .t-grad-pink {*/
/*  background: linear-gradient(90deg, #67bdff 0, #297fff 100%);*/
/*}*/

.f5 {
  font-size: 1rem;
}

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.white {
  color: #fff;
}

.w-100 {
  width: 100%;
}

.fw6 {
  font-weight: 600;
}

.fw5 {
  font-weight: 500;
}

.br1 {
  border-radius: 0.125rem;
}

.b--transparent {
  border-color: transparent;
}

.bn {
  border-style: none;
}

.bn, .bw0 {
  border-width: 0;
}

.ba {
  border-style: solid;
  border-width: 1px;
}

.outline-0 {
  outline: 0;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, hr, input {
  overflow: visible;
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.bulletin {
  min-width: 224px !important;
}

.pl4 {
  padding-left: 28px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.w5 {
  width: 224px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.no-underline {
  text-decoration: none;
}

.mv2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pl3 {
  padding-left: 1rem;
}

.mid-gray {
  color: #555;
}

.w-100 {
  width: 100%;
}

.inline-flex {
  display: inline-flex;
}

.dib {
  display: inline-block;
}

a {
  background-color: transparent;
}

.items-center, .side-menu-toggle {
  align-items: center;
}

.flex {
  display: flex;
}

.outline-0 {
  outline: 0;
}

.gray {
  color: #777;
}

.ml4 {
  margin-left: 2rem;
}

.material-icons-outlined.md-size2, .material-icons.md-size2 {
  font-size: 1.38rem;
}

.material-icons {
  display: none;
}

.mr2 {
  margin-right: 0.5rem;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.size-medium {
  min-height: 36px;
}

.concord-mid-gray, .hover-concord-mid-gray:focus, .hover-concord-mid-gray:hover {
  color: #505050;
}

.nowrap {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.f3 {
  font-size: 1.5rem;
}

.mt0 {
  margin-top: 0;
}

.mb2 {
  margin-bottom: 0.5rem;
}

.items-center, .side-menu-toggle {
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}

.gray {
  color: #777;
}

.mb3 {
  margin-bottom: 1rem;
}

.mr4 {
  margin-right: 2rem;
}

.pa3 {
  padding: 1rem;
}

.bg-white {
  background-color: #fff;
}

.w-third {
  width: 33.33333%;
}

.flex {
  display: flex;
}

.b--white {
  border-color: #fff;
}

.ba {
  border-style: solid;
  border-width: 1px;
}

.concord-blue, .hover-concord-blue:focus, .hover-concord-blue:hover {
  color: #5280FF
}

.b--concord-blue, .hover-b--concord-blue:focus, .hover-b--concord-blue:hover {
  border-color: #5280FF
}

.relative {
  position: relative;
}


.shadow {
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
}

.size-medium {
  min-height: 36px;
}

.pl5 {
  padding-left: 4rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

#buorg > div, .fw4, .history--override-bold strong {
  font-weight: 400;
}

.bn {
  border-style: none;
}

.bn, .bw0 {
  border-width: 0;
}

.outline-0 {
  outline: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, hr, input {
  overflow: visible;
}

.bg-concord-near-white {
  background-color: #F8F9FA;
  /*margin-top: 1.75rem;*/
}

.mb2 {
  margin-bottom: 0.5rem;
}

.ml3 {
  margin-left: 1rem;
}

.concord-mid-gray, .hover-concord-mid-gray:focus, .hover-concord-mid-gray:hover {
  color: #505050;
}

.top-50 {
  top: 50%;
}

.ph2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.absolute {
  position: absolute;
}

.h-100 {
  height: 100%;
}

.items-center, .side-menu-toggle {
  align-items: center;
}

.left-0 {
  left: 0;
}

.metago-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  position: relative;
}

.invite-icon {
  background-image: url("../../assets/images/invite.png");
}

.arrow-right-icon {
  background-image: url("../../assets/images/arrow-right.png");
}

.upgrade-icon {
  background-image: url("../../assets/images/upgrade.png");
}

.setting-icon {
  background-image: url("../../assets/images/setting-black.png");
}

.unfold-more-icon {
  background-image: url("../../assets/images/unfold-more.png");
  cursor: pointer;
}

.document-icon {
  background-image: url("../../assets/images/document2.png");
}

.deadlines-icon {
  background-image: url("../../assets/images/deadline.png");
}

.activity-icon {
  background-image: url("../../assets/images/historical_activity_time.png");
}

.category-icon {
  background-image: url("../../assets/images/category2.png");
}

.organization-icon {
  background-image: url("../../assets/images/organization.png");
}

.dictionary-icon {
  background-image: url("../../assets/images/dictionary.png");
}

.material-icons {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button-flex {
  display: flex;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.concord-dark-gray, .hover-concord-dark-gray:focus, .hover-concord-dark-gray:hover {
  color: #202124;
}

.bg-light-sky-blue, .focus-bg-light-sky-blue:focus, .hover-bg-light-sky-blue:focus, .hover-bg-light-sky-blue:hover {
  background-color: #80cbff
}

.bg-alto, .focus-bg-alto:focus, .hover-bg-alto:focus, .hover-bg-alto:hover {
  background-color: #d0d0d0;
}

.bg-dandelion, .focus-bg-dandelion:focus, .hover-bg-dandelion:focus, .hover-bg-dandelion:hover {
  background-color: #ffd459;
}

.bg-mona-lisa, .focus-bg-mona-lisa:focus, .hover-bg-mona-lisa:focus, .hover-bg-mona-lisa:hover {
  background-color: #ff8c8c;
}

.bg-mint, .focus-bg-mint:focus, .hover-bg-mint:focus, .hover-bg-mint:hover {
  background-color: #2CC6AB;
}

.bg-purple{
  background-color: #c297ef;
}

.bg-dark{
  background-color: #636363;
}

.mt2 {
  margin-top: 0.5rem;
}

.pt1 {
  padding-top: 0.25rem;
}

.pr4 {
  padding-right: 2rem;
}

.nl2 {
  margin-left: -0.5rem;
}

.pl2 {
  padding-left: 0.5rem;
}

.mw-70 {
  max-width: 70%;
}

.nl1 {
  margin-left: -0.25rem;
}

.pb3 {
  padding-bottom: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.mt3 {
  margin-top: 1rem;
}

.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pb3 {
  padding-bottom: 1rem;
}

.h2 {
  height: 2rem;
}

.b--light-gray, .hover-b--light-gray:focus, .hover-b--light-gray:hover {
  border-color: #EEE
}

.text-align-left {
  text-align: left;
}

.list {
  list-style-type: none;
}

.ma0 {
  margin: 0;
}

.pa0 {
  padding: 0;
}

.w-100 {
  width: 100%;
}

.outline-0 {
  outline: 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gray {
  color: #777;
}

.pdf-field-drag-handle:before, .tinymce-placeholder div, .tinymce-placeholder:before, .v-mid {
  vertical-align: middle;
}

.lh-normal {
  line-height: normal;
}

.ml1 {
  margin-left: 0.25rem;
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bg-near-white,.focus-bg-near-white:focus,.hover-bg-near-white:focus,.hover-bg-near-white:hover {
  background-color:#F4F4F4
}

.black {
  color: #000;
}

.task-item, .recently-modified-item {
  cursor: pointer;
}

.pointer:hover {
  cursor:pointer
}

.footer-icon {
  width: 20px;
  height: 20px;
}

.concord-dark-blue,.hover-concord-dark-blue:focus,.hover-concord-dark-blue:hover {
  color:#3B5DBB
}

.hover-radical-red:focus, .hover-radical-red:hover, .radical-red {
  color:#5280FF;
}

.b--radical-red, .hover-b--radical-red:focus, .hover-b--radical-red:hover {
  border-color:#5280FF;
}

.svg-size2 {
  width: 1.38rem;
  height: 1.38rem;
}

.stroke {
  stroke: currentColor;
}

.fill {
  fill: currentColor;
}

.concord-blue, .hover-concord-blue:focus, .hover-concord-blue:hover {
  color: #5280FF;
}

.f4 {
  font-size: 1.25rem;
}

.ml2 {
  margin-left: 0.5rem;
}

.dark-gray {
  color: #333;
}

.roboto {
  font-family: Roboto,"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.f6 {
  font-size: .875rem;
}
.companyItem:nth-of-type(1) .itemImg{
  background-color: rgb(106,121,221);
}
.companyItem:nth-of-type(2) .itemImg{
  background-color: rgb(178,211,253);
}
.companyItem:nth-of-type(3) .itemImg{
  background-color: rgb(236,246,255);
}
.companyItem:nth-of-type(4) .itemImg{
  background-color: rgb(243,248,255);
}
  .contract-calendar-dialog {
    background-color: #f8f9fa;
  }
.button-flex{
  line-height: normal !important;
}
#invite-btn:hover{
  background-color: var(--hoverLightBlue);
}

.index-top-right-buttons{
  margin-right: 2rem;
}
.special-action{
  color: var(--lightBlue);
  text-decoration: underline;
  display: inline-block;
  width: 100%;
}
.special-action:hover{
  color: var(--darkBlue);
}
.completeUserName-container{
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
}
.right-panel-content{
  overflow: hidden;
  height: 100%;
}
.right-panel-main{
  min-height: 0;
  flex-shrink: 1;
  margin-left: -.75rem;
  margin-top: -3px;
}
.right-panel-main .index-center-scroll{
  overflow-y: auto;
  padding-top: 3px;
  height: 100%;
}
.contract-info-card-container{

}

.task-row{
  align-items: center;
}

.task-row-left{
  width: 100% ;
}
.tags-wrapper{
  margin-right: 10px;
}

.pending-contract-title{
  text-align: left;
}
.approval-stage{
  background: #ececec;
  border-radius: 3px;
  margin-left: 10px;
  padding: 0 10px;
}

.text-btn{
  display: inline-block;
  cursor: pointer
}

.text-btn:hover{
  color: #888888;;
}
</style>
